import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { GeneralService } from './general.service';
import { PersistenceService } from 'angular-persistence';
import { environment } from '../../environments/environment';
import { MessageService } from 'primeng/components/common/messageservice';
import { Country } from '../models';
import { SelectModel } from '../models/select.model';

@Injectable()
export class CountryService extends GeneralService {
  constructor(protected messageService: MessageService, protected httpClient: HttpClient, protected persistenceService: PersistenceService) {
    super(messageService, httpClient, persistenceService, environment.apiGeneralUrl, "country");
  }
  public async getSelectModelAllAsync(): Promise<SelectModel[]> {
    const res = await this.getAllAsync();
    const data: SelectModel[] = [];
    data.push({ label: "-- Chọn quốc gia --", value: null });
    if (res) {
      res.forEach(element => {
        data.push({ label: element.name, value: element.id, data: element });
      });
      return data;
    } else {
      return null;
    }
  }
  async getCountryByNameAsync(name?: any, pageNum?: any, pageSize?: any): Promise<SelectModel[]> {
    let params = new HttpParams();
    if (name) {
      params = params.append("name", name);
    }
    if (pageNum) {
      params = params.append("pageNumber", pageNum);
    }
    if (pageSize) {
      params = params.append("pageSize", pageSize);
    }
    var res = await super.getCustomApi("GetCountryByName", params).toPromise();
    if (this.isValidResponse(res)) {
      var data = res.data as Country[];
      const countrys: SelectModel[] = [];
      // cusDepartments.push({ label: `-- Chọn quốc gia --`, value: null });
      if (data) {
        data.forEach(element => {
          countrys.push({
            label: `${element.name}`,
            value: element.id,
            data: element
          });
        });
        return countrys;
      } else {
        return null;
      }
    }

  }
}