export class GMapHelper {
    static STREET_NUMBER = "street_number";
    static ROUTE = "route";
    static SUBLOCALITY_LEVEL_1 = "sublocality_level_1";
    static SUBLOCALITY = "sublocality";
    static POLITICAL = "political";
    static LOCALITY = "locality";
    static ADMINISTRATIVE_AREA_LEVEL_3 = "administrative_area_level_3";
    static ADMINISTRATIVE_AREA_LEVEL_2 = "administrative_area_level_2";
    static ADMINISTRATIVE_AREA_LEVEL_1 = "administrative_area_level_1";
    static COUNTRY = "country";
}