import { GeneralInfoModel } from "../models/generalInfo.model";

export class IncomingPaymentViewModel {
    id: number;
    bankAccount: string;
    paymentDate: any;
    grandTotalReal: number;
    cashFlowCode: string;
    //
    shipmentId: number;
    customerCode: string;
    docTotal: number;
    total: number;
    docDate: any;
    paymentType: string;
    transferDate: any;
    documentNo: string;
    createdByFullName: string;
    createdByCode: string;
    listReceiveMoneyCode: string;
    isPushIncomming?: boolean;
    shipmentNumber: string;
    isPush?: boolean;
    note: string;
    flag?: number;
    isTest: number;
    cusConnectCode: string;
}