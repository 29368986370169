import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { GeneralService } from './general.service';
import { PersistenceService } from 'angular-persistence';
import { environment } from '../../environments/environment';
import { MessageService } from 'primeng/components/common/messageservice';
import { SelectModel } from '../models/select.model';
import { data } from 'jquery';

@Injectable()
export class RoleService  extends GeneralService {
  constructor(protected messageService: MessageService, protected httpClient: HttpClient, protected persistenceService: PersistenceService) {
    super(messageService, httpClient, persistenceService, environment.apiGeneralUrl, "role");
  }
  
  async getSelectModelAsync(): Promise<SelectModel[]> {
    const res = await this.getAllAsync();
    const role: SelectModel[] = [];
    role.push({ label: "-- Chọn chức vụ --", value: null });
    if (res) {
      res.data.forEach(element => {
        role.push({ label: element.name, value: element.id, data: element });
      });
      return role;
    } else {
      return null;
    }
  }
}
